<template>
  <div>
    <b-row>
      <!-- Post Details -->
      <b-col lg="12">
        <b-card>
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner
              variant="success"
              label="Spinning"
              style="width: 3rem; height: 3rem"
            ></b-spinner>
          </div>
          <b-row>
            <b-col lg="12">
              <b-card>
                <b-row>
                  <b-col lg="12">
                    <b-form>
                      <b-form-group>
                        <b-row class="align-items-center justify-content-end mb-1 w-100">
                          <b-col lg="3">
                            <div class="float-right">
                              <b-button @click="openMediaDialog" variant="secondary"
                                >Browse & Upload</b-button
                              >
                              <input
                                type="file"
                                ref="mediaInput"
                                class="d-none"
                                @change="handleMediaUpload"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-form>
                  </b-col>
                  <b-col lg="12">
                    <b-table
                      :items="formData.mediaURLs"
                      :fields="tableFields"
                      hover
                      responsive
                    >
                      <template #cell(mediaName)="row">
                        {{ row.item.mediaName }}
                      </template>
                      <!-- Download Button Column -->
                      <template #cell(download)="row">
                        <b-button
                          @click="getFileById(row.item)"
                          variant="success"
                          size="sm"
                          >Download</b-button
                        >
                      </template>
                      <template #cell(actions)="row">
                        <b-button
                          @click="confirmDelete(row.item)"
                          variant="danger"
                          size="sm"
                          >Delete</b-button
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BButton,
  BRow,
  BCol,
  BCard,
  BButtonGroup,
  BFormTextarea,
  BSkeleton,
  BTable,
  BImg,
  BSkeletonImg,
  VBTooltip,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import api from "@/api/api-v2-config";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    vSelect,
    BSpinner,
    BCard,
    BButtonGroup,
    BFormTextarea,
    BSkeleton,
    BTable,
    BImg,
    Swiper,
    SwiperSlide,
    BSkeletonImg,
    BFormFile,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      formData: {
        mediaURLs: [],
      },
      tableFields: [
        { key: "mediaName", label: "File Name" },
        { key: "download", label: "", sortable: false },
        { key: "actions", label: "", sortable: false },
      ],
    };
  },
  methods: {
    openMediaDialog() {
      this.$refs.mediaInput.click();
    },

    async uploadFile(file) {
      let formData = new FormData();
      formData.append("IsActive", true);
      formData.append("Name", file.name);
      formData.append("Type", 1);
      formData.append("LeadClientId", this.id);
      formData.append("File", file);
      this.$swal({
        title: "Uploading...",
        text: "Please wait while the file is being uploaded.",
        icon: "info",
        buttons: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      });
      try {
        const response = await api.post("/files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          this.$swal({
            icon: "success",
            title: "Uploaded!",
            text: "The file has been uploaded successfully.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getFiles(this.id);
        } else {
          this.$swal({
            icon: "error",
            title: response,
            text: "The file could not be uploaded.",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
          this.getFiles(this.id);
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: error.response.data,
          customClass: {
            confirmButton: "btn btn-warning",
          },
        });
        this.getFiles(this.id);
      }
    },

    getFileById(item) {
      function getMimeTypeFromFileName(filename) {
        const extension = filename.split(".").pop().toLowerCase();
        const mimeTypes = {
          pdf: "application/pdf",
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          txt: "text/plain",
          csv: "text/csv",
          doc: "application/msword",
          docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          xls: "application/vnd.ms-excel",
          xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ppt: "application/vnd.ms-powerpoint",
          pptx:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          mp3: "audio/mpeg",
          wav: "audio/wav",
          mp4: "video/mp4",
          mov: "video/quicktime",
          avi: "video/x-msvideo",
          zip: "application/zip",
          rar: "application/x-rar-compressed",
          "7z": "application/x-7z-compressed",
          js: "application/javascript",
          css: "text/css",
          html: "text/html",
          xml: "application/xml",
        };

        return mimeTypes[extension] || "application/octet-stream";
      }
      this.$swal({
        title: "Downloading...",
        text: "Please wait while the file is being downloaded.",
        didOpen: () => {
          this.$swal.showLoading();
        },
        allowOutsideClick: false,
      });
      api
        .get(`/files/${item.id}`)
        .then((response) => {
          const base64 = response.data.result[0].file;
          const filename = response.data.result[0].name;
          const mimeType = getMimeTypeFromFileName(filename);
          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: mimeType });
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
          this.$swal.close();
        })
        .catch((error) => {
          console.error("Error fetching file:", error);
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "There was a problem downloading the file.",
            footer: "Try again later",
          });
        });
    },

    getFiles(id) {
      this.loading = true;
      this.formData.mediaURLs = [];
      api
        .get(`/files/byleadclientid/${id}`)
        .then((response) => {
          if (response.data.success) {
            response.data.result.forEach((fileData) => {
              this.processFileData(fileData);
            });
          } else {
            console.warn("API did not return success:", response.data.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching files:", error);
          this.loading = false;
        });
    },

    processFileData(fileData) {
      if (!fileData || !fileData.name || !fileData.id || !fileData.path) {
        console.warn("Incomplete file data:", fileData);
        return;
      }
      const mediaItem = {
        id: fileData.id,
        url: fileData.path,
        mediaName: fileData.name,
        mediaSize: null,
        mediaType: this.getFileType(fileData.name),
      };
      this.formData.mediaURLs.push(mediaItem);
    },

    processFileData(fileData) {
      if (!fileData || !fileData.name || !fileData.id || !fileData.path) {
        console.warn("Incomplete file data:", fileData);
        return;
      }
      const mediaItem = {
        id: fileData.id,
        url: fileData.path,
        mediaName: fileData.name,
      };
      this.formData.mediaURLs.push(mediaItem);
    },
    handleMediaUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    deleteNotes(taskId) {
      this.loading = true;
      let payload = {
        id: taskId,
      };
      api
        .delete(`/files`, { data: payload })
        .then((response) => {
          this.getFiles(this.id);
          this.loading = false;
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "The File has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((error) => {
          this.getNotes(this.id);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "not deleted!",
            text: "The File has been deleted.",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
        });
    },

    // deleting a note
    confirmDelete(note) {
      this.$swal({
        title: "Delete File?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteNotes(note.id);
        }
      });
    },
  },
  mounted() {
    this.getFiles(this.id);
  },
};
</script>
<style scoped>
img#media-img-table {
  height: 50px;
  padding: 5px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
