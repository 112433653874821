<template>
  <b-tabs>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        variant="success"
        label="Spinning"
        block
        v-if="loading"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-card v-if="!leadId.isClient">
      <b-button variant="secondary" class="float-right" @click="makeCustomer()">
        Make Customer
      </b-button>
    </b-card>
    <b-tab title="Contacts" active>
      <ContactList />
    </b-tab>
    <b-tab title="Lead Info">
      <b-card>
        <LeadInfo />
      </b-card>
    </b-tab>
    <b-tab title="Quotation">
      <b-card>
        <Quotation />
      </b-card>
    </b-tab>
    <b-tab title="Note">
      <ToDo />
    </b-tab>
    <b-tab title="File Management">
      <FileMgt />
    </b-tab>
  </b-tabs>
</template>

<script>
import ContactList from "./ContactList.vue";
import LeadInfo from "./LeadInfo.vue";
import Quotation from "./Quotation.vue";
import FileMgt from "./FileMgt.vue";
import ToDo from "./ToDo.vue";
import { BTabs, BTab, BSpinner, BCardText, BCard, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTabs,
    BCardText,
    BCard,
    BTab,
    ToDo,
    BButton,
    BSpinner,
    ContactList,
    LeadInfo,
    Quotation,
    FileMgt,
  },

  data() {
    return {
      id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("leadModule", {
      leadId: "leadId",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("leadModule", ["getLeadByIDAction", "getLeadConvertAction"]),
    successAdd() {
      this.$swal({
        title: "Lead converted!",
        text: "Lead successfully converted to Customer",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    errorAdd() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    makeCustomer() {
      this.getLeadConvertAction(this.id)
        .then(() => {
          this.successAdd();
          this.$router.push({ name: "customer-list" });
        })
        .catch((error) => {
          this.errorAdd();
        });
    },
  },
  async mounted() {
    await this.getLeadByIDAction(this.id);
  },
};
</script>
