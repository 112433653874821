<template>
  <div>
    <validation-observer
      ref="formIndividual"
      @change="submitForm"
      v-if="individualCheck"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="addLeadsIndividual">
        <b-row>
          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Lead Source"
              vid="Lead Source"
            >
              <b-form-group label="Lead Source" label-for="h-First">
                <b-form-select v-model="leadId.source" :options="socialMedias" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- username -->

          <b-col cols="12">
            <b-form-group label="Sales Person" label-for="h-First">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="Lead Source"
                vid="Lead Source"
              >
                <b-form-select
                  v-model="leadId.salesPersonId"
                  :options="optionsSalesPerson"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Full Name" label-for="h-company">
              <validation-provider rules="required" name="Full Name" #default="{ errors }">
                <b-form-input
                  id="h-full"
                  placeholder="Full Name"
                  v-model="leadId.otherName"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- email -->
          <b-col cols="12">
            <validation-provider rules="required|email" name="Email" v-slot="{ errors }">
              <b-form-group label="Email" label-for="d-email">
                <b-form-input
                  id="d-email"
                  type="email"
                  placeholder="Email"
                  v-model="leadId.email"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Address" v-slot="{ errors }">
              <b-form-group label="Address" label-for="h-Address">
                <b-form-input
                  id="h-Address"
                  placeholder="Address"
                  v-model="leadId.addressLine1"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Street Name" v-slot="{ errors }">
              <b-form-group label="Street Name" label-for="h-Street">
                <b-form-input
                  id="h-Street"
                  placeholder="Street Name"
                  v-model="leadId.addressLine2"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="City" v-slot="{ errors }">
              <b-form-group label="City" label-for="h-City">
                <b-form-input id="h-City" placeholder="City" v-model="leadId.city" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Tel" v-slot="{ errors }">
              <b-form-group label="Tel" label-for="h-tel">
                <b-form-input id="h-tel" placeholder="Tel" type="number" onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="leadId.tel" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="job Title" label-for="h-title">
              <b-form-input
                id="h-title"
                placeholder="Job title"
                v-model="leadId.jobTitle"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Products interested in" label-for="h-title">
              <b-form-textarea
                id="event-description"
                placeholder="Products interested in"
                v-model="leadId.comments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              <b-spinner small v-if="loading" />
              Submit
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <validation-observer ref="formCompany" v-if="!individualCheck" @change="submitForm">
      <b-form @submit.prevent="addLeadsCompany">
        <b-row>
          <!-- username -->
          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Lead Source"
              vid="Lead Source"
            >
              <b-form-group label="Lead Source" label-for="h-First">
                <b-form-select v-model="leadId.source" :options="socialMedias" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Sales Person" label-for="h-First">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="Sales Person"
                vid="Sales Person"
              >
                <b-form-select
                  v-model="leadId.salesPersonId"
                  :options="optionsSalesPerson"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Company Name" label-for="h-company">
              <validation-provider rules="required" name="Company Name" #default="{ errors }">
                <b-form-input
                  id="h-full"
                  placeholder="Company Name"
                  v-model="leadId.otherName"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- email -->
          <b-col cols="12">
            <validation-provider rules="required|email" name="Email" v-slot="{ errors }">
              <b-form-group label="Email" label-for="d-email">
                <b-form-input
                  id="d-email"
                  type="email"
                  placeholder="Email"
                  v-model="leadId.email"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Address" v-slot="{ errors }">
              <b-form-group label="Address" label-for="h-Address">
                <b-form-input
                  id="h-Address"
                  placeholder="Address"
                  v-model="leadId.addressLine1"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Street Name" v-slot="{ errors }">
              <b-form-group label="Street Name" label-for="h-Street">
                <b-form-input
                  id="h-Street"
                  placeholder="Street Name"
                  v-model="leadId.addressLine2"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="City" v-slot="{ errors }">
              <b-form-group label="City" label-for="h-City">
                <b-form-input id="h-City" placeholder="City" v-model="leadId.city" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Company Tel" v-slot="{ errors }">
              <b-form-group label="Company Tel" label-for="h-tel">
                <b-form-input id="h-tel" placeholder="Company Tel" type="number" onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="leadId.tel" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Website" v-slot="{ errors }">
              <b-form-group label="Website" label-for="h-Website">
                <b-form-input
                  id="h-Website"
                  placeholder="Website"
                  v-model="leadId.website"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Vat Number" label-for="h-Number">
              <b-form-input
                id="h-Number"
                placeholder="Vat Number"
                v-model="leadId.vatNumber"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider rules="required" name="Company BRN" v-slot="{ errors }">
              <b-form-group label="Company BRN" label-for="h-Number">
                <b-form-input
                  id="h-Number"
                  placeholder="Company BRN"
                  v-model="leadId.brn"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Products interested in" label-for="h-title">
              <b-form-textarea
                id="event-description"
                placeholder="Products interested in"
                v-model="leadId.comments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      optionsSalesPerson: [],
      socialMedias: [
        { text: "Other", value: 0 },
        { text: "Facebook", value: 1 },
        { text: "Instagram", value: 2 },
        { text: "Twitter", value: 3 },
        { text: "LinkedIn", value: 4 },
      ],
      selected: "Select Social Media",
      ///////////////
      required,
      email,
      individualCheck: false,
    };
  },

  computed: {
    ...mapGetters("leadModule", {
      leadId: "leadId",
      loading: "loading",
    }),

    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },

  async mounted() {
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
    await this.getLeadByIDAction(this.id).then((res) => {
      if (this.leadId.typeDesc === "INDIVIDUAL") {
        this.individualCheck = true;
        this.selected = this.leadId.sourceDesc;
      } else {
        this.individualCheck = false;
      }
    });
  },
  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("leadModule", ["getLeadByIDAction", "updateLeadAction"]),

    saveSocialMedia(value, text) {
      this.leadId.source = value;
      this.selected = text;
    },

    addLeadsIndividual() {
      this.$refs.formIndividual.validate().then((success) => {
        if (success) {
          let payload = {
            id: this.leadId.id,
            otherName: this.leadId.otherName,
            email: this.leadId.email,
            addressLine1: this.leadId.addressLine1,
            addressLine2: this.leadId.addressLine2,
            city: this.leadId.city,
            tel: this.leadId.tel,
            jobTitle: this.leadId.jobTitle,
            source: this.leadId.source,
            comments: this.leadId.comments,
            salesPersonId: this.leadId.salesPersonId,
          };
          this.$swal({
            title: "Updating Lead",
            text: "Please wait while the Lead is being updated...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading();
              this.updateLeadAction(payload)
                .then(() => {
                  this.$swal.hideLoading();
                  this.success();
                })
                .catch(() => {
                  this.$swal.hideLoading();
                  this.error();
                });
            },
          });
        }
      });
    },

    addLeadsCompany() {
      let payload = {
        id: this.leadId.id,
        otherName: this.leadId.otherName,
        email: this.leadId.email,
        companyName: this.leadId.companyName,
        addressLine1: this.leadId.addressLine1,
        addressLine2: this.leadId.addressLine2,
        city: this.leadId.city,
        brn: this.leadId.brn,
        tel: this.leadId.tel,
        website: this.leadId.website,
        vatNumber: this.leadId.vatNumber,
        source: this.leadId.source,
        comments: this.leadId.comments,
        type: 1,
        status: 0,
        isActive: true,
        salesPersonId: this.leadId.salesPersonId,
      };
      this.$swal({
        title: "Updating Lead",
        text: "Please wait while the Lead is being updated...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
          this.updateLeadAction(payload)
            .then(() => {
              this.$swal.hideLoading();
              this.success();
            })
            .catch(() => {
              this.$swal.hideLoading();
              this.error();
            });
        },
      });
    },
    success() {
      this.$swal({
        title: "Leads!",
        text: "You have successfully updated the lead!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.$router.push({ name: "leads-list" });
      });
    },

    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    resetForm() {
      this.$swal({
        title: "Resetting Form",
        text: "Please wait while the form is being reset...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
          this.getLeadByIDAction(this.id)
            .then((res) => {
              this.$swal.hideLoading();
              if (this.leadId.typeDesc === "INDIVIDUAL") {
                this.individualCheck = true;
              } else {
                this.individualCheck = false;
              }
              this.$swal({
                title: "Success",
                text: "The form has been reset.",
                icon: "success",
              });
            })
            .catch((error) => {
              this.$swal.hideLoading();
              this.$swal({
                title: "Error",
                text: `Error resetting form: ${error.message}`,
                icon: "error",
              });
            });
        },
      });
    },

    submitForm() {
      // emit the data to another page
      this.leadId.companyName = this.leadId.type == 1 ? this.leadId.otherName : null;
      this.$emit("onchange", this.individual);
    },
  },
};
</script>
