<template>
  <div>
    <b-row class="justify-content-end">
      <b-col md="10" class="my-1"> </b-col>
      <b-col lg="2">
        <b-button variant="success" class="float-right my-1" @click="openAddNoteModal"
          ><feather-icon icon="PlusIcon" />Add Note</b-button
        >
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-row>
      <b-col lg="12">
        <b-card v-for="note in sortedNotes" :key="note.title">
          <b-row>
            <b-col lg="4">
              <h4>{{ note.title ? note.title : "-N/A-" }}</h4>
            </b-col>
            <b-col lg="2">
              <h5>
                {{
                  getSalePersonName(note.salesPersonId)
                    ? getSalePersonName(note.salesPersonId)
                    : "-N/A-"
                }}
              </h5>
              <h6>
                <feather-icon icon="ClockIcon" />
                {{ formatDate(note.dueDate) ? formatDate(note.dueDate) : "-N/A-" }}
              </h6>
            </b-col>
            <b-col lg="3">
              <b-badge variant="bg-transparent" class="d-flex">
                <feather-icon
                  icon="CircleIcon"
                  :class="['mr-1 text-' + getImportanceVariant(note.isImportant)]"
                  size="20"
                />
                <h5>{{ note.isImportant ? "Important" : "Mild" }}</h5>
              </b-badge>
            </b-col>
            <b-col lg="2">
              <h6>
                Created On:
                <br />
                {{ note.created_On ? formatDate(note.created_On) : "-N/A-" }}
              </h6>
            </b-col>
            <b-col lg="1">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item @click="openEditModal(note)">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>View / Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="confirmDelete(note)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--- Add Note Modal -->
    <b-modal
      id="addNoteModal"
      title="Add Note"
      v-model="showAddNoteModal"
      centered
      @ok="handleAddNote"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Note Title"
            label-for="noteTitleInput"
            :state="titleValidation"
            :invalid-feedback="titleError"
          >
            <b-form-input
              id="noteTitleInput"
              required
              v-model="title"
              @blur="validateTitle"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Tag" label-for="tagInput">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionTags"
              label="title"
              v-model="selectedTag"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Sales Person" label-for="salesPersonInput">
            <b-form-select v-model="salesPersonId" :options="optionsSalesPerson" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Due Date"
            label-for="dueDateInput"
            :state="dueDateValidation"
            :invalid-feedback="dueDateError"
          >
            <b-form-datepicker
              id="example-purchase"
              class="mb-1"
              v-model="dueDate"
              required
              @blur="validateDueDate"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Description" label-for="editDescription">
            <b-form-textarea id="editDescription" v-model="description"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="handleAddNote">
          <b-spinner v-if="loadingAdd" type="grow" small></b-spinner>
          <span v-if="!loading">Add Note</span>
        </b-button>
      </template>
    </b-modal>
    <!-- View Note Modal -->
    <b-modal id="viewModal" title="View Note" v-model="showViewModal" centered>
      <p><strong>Note Title: </strong>{{ selectedNote.title }}</p>

      <p>
        <strong>Sales Person: </strong>{{ getSalePersonName(selectedNote.salesPersonId) }}
      </p>
      <p><strong>Description: </strong>{{ selectedNote.description }}</p>
      <p><strong>Date: </strong>{{ formatDate(selectedNote.dueDate) }}</p>
      <p>
        <strong>Status: </strong
        ><b-badge variant="bg-transparent" class="d-flex">
          <feather-icon
            icon="CircleIcon"
            :class="['mr-1 text-' + getImportanceVariant(selectedNote.isImportant)]"
            size="20"
          />
          <h5>{{ selectedNote.isImportant ? "Important" : "Mild" }}</h5>
        </b-badge>
      </p>
      <template #modal-footer></template>
    </b-modal>
    <!-- Edit Note Modal -->
    <b-modal id="editNoteModal" title="Add Note" v-model="showEditNoteModal" centered>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Note Title"
            label-for="noteTitleInput"
            :state="editTitleValidation"
            :invalid-feedback="editTitleError"
          >
            <b-form-input
              id="noteTitleInput"
              v-model="selectedNote.title"
              required
              @blur="validateEditTitle"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Tag" label-for="tagInput">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionTags"
              label="title"
              v-model="selectedTag"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Sales Person" label-for="salesPersonInput">
            <b-form-select
              v-model="selectedNote.salesPersonId"
              :options="optionsSalesPerson"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Due Date"
            label-for="dueDateInput"
            :state="editDueDateValidation"
            :invalid-feedback="editDueDateError"
          >
            <b-form-datepicker
              id="example-purchase"
              class="mb-1"
              v-model="selectedNote.dueDate"
              required
              @blur="validateEditDueDate"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Description" label-for="editDescription">
            <b-form-textarea
              id="editDescription"
              v-model="selectedNote.description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="handleEditNote">
          <b-spinner v-if="loading" type="grow" small></b-spinner>
          <span v-if="!loading">Update Note</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BCard,
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BDropdown,
  BSpinner,
  BDropdownItem,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import api from "@/api/api-v2-config";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormSelect,
  },
  data() {
    return {
      titleValidation: null,
      descriptionValidation: null,
      dueDateValidation: null,
      titleError: "",
      descriptionError: "",
      dueDateError: "",
      editTitleValidation: null,
      editDescriptionValidation: null,
      editDueDateValidation: null,
      editTitleError: "",
      editDescriptionError: "",
      editDueDateError: "",
      id: this.$route.params.id,
      loading: false,
      loadingAdd: false,
      title: "",
      description: "",
      selectedStatus: "",
      selectedTag: "",
      salesPersonId: "",
      showAddNoteModal: false,
      showViewModal: false,
      showEditNoteModal: false,
      selectedNote: {},
      dueDate: null,
      optionsSalesPerson: [],
      isImportant: false,
      notes: [],
      optionTags: [
        { title: "Mild", value: false },
        { title: "Important", value: true },
      ],
    };
  },

  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    sortedNotes() {
      return this.notes.sort((a, b) => {
        const dateA = new Date(a.created_On);
        const dateB = new Date(b.created_On);
        return dateB - dateA; // Sort in descending order
      });
    },
  },
  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    formatDate(isoString) {
      const date = new Date(isoString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed in JavaScript
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    handleAddNote() {
      this.addNotes();
    },
    handleEditNote() {
      this.validateEditTitle();
      this.validateEditDueDate();

      if (this.editTitleValidation && this.editDueDateValidation) {
        this.editNote();
      }
    },

    validateEditTitle() {
      if (!this.selectedNote.title) {
        this.editTitleValidation = false;
        this.editTitleError = "Title is required";
      } else {
        this.editTitleValidation = true;
        this.editTitleError = "";
      }
    },

    validateEditDescription() {
      if (!this.selectedNote.description) {
        this.editDescriptionValidation = false;
        this.editDescriptionError = "Description is required";
      } else {
        this.editDescriptionValidation = true;
        this.editDescriptionError = "";
      }
    },

    validateEditDueDate() {
      if (!this.selectedNote.dueDate) {
        this.editDueDateValidation = false;
        this.editDueDateError = "Due date is required";
      } else {
        this.editDueDateValidation = true;
        this.editDueDateError = "";
      }
    },
    validateTitle() {
      if (!this.title) {
        this.titleValidation = false;
        this.titleError = "Title is required";
      } else {
        this.titleValidation = true;
        this.titleError = "";
      }
    },
    validateDescription() {
      if (!this.description) {
        this.descriptionValidation = false;
        this.descriptionError = "Description is required";
      } else {
        this.descriptionValidation = true;
        this.descriptionError = "";
      }
    },
    validateDueDate() {
      if (!this.dueDate) {
        this.dueDateValidation = false;
        this.dueDateError = "Due date is required";
      } else {
        this.dueDateValidation = true;
        this.dueDateError = "";
      }
    },
    editNote() {
      this.loading = true;
      const payload = {
        id: this.selectedNote.id,
        title: this.selectedNote.title,
        salesPersonId: this.selectedNote.salesPersonId,
        dueDate: this.selectedNote.dueDate,
        tag: this.selectedTag.value ? "Important" : "Mild",
        description: this.selectedNote.description,
        isImportant: this.selectedTag.value,
        isActive: this.selectedNote.isActive,
        leadClientId: this.id,
      };

      api
        .put(`/tasks`, payload)
        .then((response) => {
          this.loading = false;
          this.getNotes(this.id);
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Note updated successfully.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.showEditNoteModal = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Error!",
            text: "Failed to update note.",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
        });
    },

    getImportanceVariant(isImportant) {
      return isImportant ? "danger" : "info";
    },

    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      console.log(salesPerson);
      console.log(this.SalesPersonList);
      return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
    },
    getNotes(id) {
      this.loading = true;
      api
        .get(`/tasks/byleadclientid/${id}`)
        .then((response) => {
          this.notes = response.data.result;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    addNotes() {
      this.loadingAdd = true;
      this.validateTitle();
      this.validateDueDate();
      if (this.titleValidation && this.dueDateValidation) {
        let payload = {
          title: this.title,
          salesPersonId: this.salesPersonId,
          dueDate: this.dueDate,
          tag: this.isImportant ? "Important" : "Mild",
          description: this.description,
          isImportant: this.selectedTag.value,
          isActive: true,
          leadClientId: this.id,
        };
        api
          .post("/tasks", payload)
          .then((response) => {
            this.getNotes(this.id);
            this.$swal({
              icon: "success",
              title: "Success!",
              text: "Note added successfully.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });

            this.title = "";
            this.salesPersonId = "";
            this.dueDate = null;
            this.description = "";
            this.selectedTag = "";
            this.isImportant = false;
            this.showAddNoteModal = false;
          })
          .catch((error) => {
            this.loadingAdd = false;
            this.$swal({
              icon: "error",
              title: "Error!",
              text: "Failed to add note.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
      }
    },
    openAddNoteModal() {
      this.showAddNoteModal = true;
    },
    openViewModal(note) {
      this.selectedNote = note;
      this.showViewModal = true;
    },
    openEditModal(note) {
      this.selectedNote = note;
      this.selectedTag = this.optionTags.find((tag) => tag.value === note.isImportant);
      this.showEditNoteModal = true;
    },
    getTypeVariant() {
      return this.isImportant ? "danger" : "info";
    },

    getTagVariant(tag) {
      switch (tag) {
        case "Important":
          return "danger";
        case "Mild":
          return "info";
        default:
          return "secondary";
      }
    },

    deleteNotes(taskId) {
      this.loading = true;
      let payload = {
        id: taskId,
      };
      api
        .delete(`/tasks`, { data: payload })
        .then((response) => {
          this.getNotes(this.id);
          this.loading = false;
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "The note has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((error) => {
          this.getNotes(this.id);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "not deleted!",
            text: "The note has been deleted.",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
        });
    },

    // deleting a note
    confirmDelete(note) {
      this.$swal({
        title: "Delete Note?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteNotes(note.id);
        }
      });
    },
  },

  async mounted() {
    this.getNotes(this.id);

    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
  },

  props: {
    heading: {
      type: String,
      default: "To Do",
    },
  },
};
</script>
<style lang="scss" scoped>
.is-done {
  color: #666;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
